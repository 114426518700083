.swiper-line {
  padding-top: 12.8rem;

  .swiper-wrapper {
    position: relative;

    &::before {
      content: '';
      width: 150%;
      height: 10.5rem;
      background: url('~/public/images/curve.svg') center no-repeat;
      background-size: 100%;
      display: block;
      position: absolute;
      top: 20.2rem;
      left: 17rem;
      z-index: 0;
    }
  }
}

.swiper-arrow-circle {
  .swiper-button-next,
  .swiper-button-prev {
    top: 3.2rem;
    width: 6.4rem;
    height: 6.4rem;
  }

  .swiper-button-next {
    right: 4rem;

    &::after {
      content: '';
      background: url('~/public/images/arrow-circle.svg') center no-repeat;
      background-size: 98%;
      width: 100%;
      height: 100%;
    }
  }

  .swiper-button-prev {
    right: 11.2rem;
    left: initial;

    &::after {
      content: '';
      background: url('~/public/images/arrow-circle.svg') center no-repeat;
      background-size: 98%;
      transform: rotate(180deg);
      width: 100%;
      height: 100%;
    }
  }

  &.swiper-arrow-circle_center {
    .swiper-button-next {
      top: 31.5rem;
      right: 0;
    }
    &.swiper-arrow-circle_center {
      .swiper-button-next {
        top: 31.5rem;
        right: 0;
      }

      .swiper-button-prev {
        top: 31.5rem;
        right: 7.2rem;
      }
    }
    .swiper-button-prev {
      top: 31.5rem;
      right: 7.2rem;
    }
  }

  &.swiper-arrow-circle_mentors-on-main {
    .swiper-button-next {
      top: 31.5rem;
      left: auto;
      right: 0;
    }

    .swiper-button-prev {
      top: 31.5rem;
      left: auto;
      right: 7.2rem;
    }
  }

  &.swiper-arrow-circle_bottom {
    .swiper-button-next {
      top: initial;
      bottom: 5.8rem;
      left: 96.8rem;
    }
    &.swiper-arrow-circle_mentors-on-main {
      .swiper-button-next {
        top: 31.5rem;
        left: auto;
        right: 0;
      }

      .swiper-button-prev {
        top: 31.5rem;
        left: auto;
        right: 7.2rem;
      }
    }

    &.swiper-arrow-circle_bottom {
      .swiper-button-next {
        top: initial;
        bottom: 5.8rem;
        left: 96.8rem;
      }

      .swiper-button-prev {
        top: initial;
        bottom: 5.8rem;
        left: 89.6rem;
      }
    }

    &.swiper-no-arrows {
      .swiper-button-next,
      .swiper-button-prev {
        display: none;
      }
    }
  }
  &.swiper-no-arrows {
    .swiper-button-next,
    .swiper-button-prev {
      display: none;
    }
  }
}

.swiper-wrapper-align-center {
  .swiper-wrapper {
    align-items: center;
  }
}

.swiper-custom-fade .swiper-slide > div {
  opacity: 0;
}

.swiper-custom-fade .swiper-slide-active {
  opacity: 1 !important;
}

.swiper-custom-fade .swiper-slide-active > div {
  opacity: 1;
}
