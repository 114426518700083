@import './variables';

/*********************************************************/
/*********************  TYPOGRAPHY  **********************/
/*********************************************************/

/**********************  Heading  ************************/

h1,
h2,
h3,
h4 {
  margin: 0;
}

h1 {
  @include font-codec(bold, 96, 96);
  @media screen and (max-width: 1023px) {
    @include font-codec(bold, 48, 48);
  }
}

h2 {
  @include font-codec(bold, 64, 64);
  white-space: pre-wrap;
  @media screen and (max-width: 1023px) {
    @include font-codec(bold, 40, 40);
  }
}

h3 {
  @include font-codec(bold, 40, 40);
  @media screen and (max-width: 1023px) {
    @include font-codec(bold, 28, 28);
  }
}

h4 {
  @include font-codec(bold, 32, 36);
  @media screen and (max-width: 1023px) {
    @include font-codec(bold, 24, 24);
  }
}

h5 {
  @include font-codec(bold, 24, 32);
  @media screen and (max-width: 1023px) {
    @include font-codec(bold, 16, 20);
  }
}

/*********************  Paragraph  ************************/

p {
  @include font-codec(Regular, 24, 32);
  @media screen and (max-width: 1023px) {
    @include font-codec(Regular, 18, 24);
  }
  .thin {
    @include font-codec(Regular, 24, 32);
    @media screen and (max-width: 1023px) {
      @include font-codec(Regular, 18, 24);
    }
  }
}

b {
  font-family: 'CodecPro-Bold', sans-serif;
  appearance: none;
  -webkit-box-shadow: none;
  -webkit-appearance: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

strong {
  font-family: 'CodecPro-Bold', sans-serif;
  appearance: none;
  -webkit-box-shadow: none;
  -webkit-appearance: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.marked-list {
  ul,
  ol {
    position: relative;
    display: flex;
    flex-direction: column;
    row-gap: getRem(16);
    margin-bottom: getRem(16);
    &:last-child {
      margin-bottom: 0;
    }
  }
  ul {
    li {
      margin-left: 0.6em;
      &::marker {
        display: none;
        content: none;
      }
      &::before {
        position: absolute;
        content: '•';
        font-size: 1em;
        left: 0;
      }
    }
  }
  ol {
    li {
      margin-left: 1em;
    }
  }
}
