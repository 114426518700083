.for-student {
  display: flex;
  align-items: center;
  color: #ffffff;
  text-decoration: none;
  z-index: 15;
  white-space: nowrap;
  margin: 0;
  border: none;
  border-radius: 0;
  padding: 0;
  column-gap: 0;
  @media screen and (min-width: 1024px) {
    column-gap: 1rem;
    margin-left: auto;
    margin-right: 3.2rem;
    border: 1px solid #fff;
    border-radius: 1.6rem;
    padding: 0.9rem 1.6rem 0.8rem;
  }
}

.arrow {
  width: 1.9rem;
  height: 2.2rem;
}
