.gradient-one {
  position: absolute;
  right: 42.6rem;
  bottom: -35%;
  width: 97.6rem;
  height: 76.9rem;

  background: #0011aa;
  background: radial-gradient(circle, #0011aa 0%, rgba(0, 0, 0, 0) 60%);
  background-size: contain;
  background-repeat: no-repeat;
}

.gradient-two {
  position: absolute;
  top: -20rem;
  right: -15rem;
  width: 96rem;
  height: 99.2rem;

  background: #39dbff;
  background: radial-gradient(circle, #39dbff 0%, rgba(0, 0, 0, 0) 60%);
  background-size: contain;
  background-repeat: no-repeat;
  transform: rotate(20deg);
}

.gradient-three {
  position: absolute;
  top: 23.8rem;
  right: -33rem;
  width: 110rem;
  height: 86.8rem;

  background: #39dbff;
  background: radial-gradient(circle, #39dbff 0%, rgba(0, 0, 0, 0) 60%);
  background-size: contain;
  background-repeat: no-repeat;
  transform: rotate(109deg);
}

.gradient-four {
  position: absolute;
  top: -24rem;
  right: -30rem;
  width: 63rem;
  height: 50rem;

  background: #0011aa;
  background: radial-gradient(circle, #0011aa 0%, rgba(0, 0, 0, 0) 60%);
  background-size: contain;
  background-repeat: no-repeat;
}
