@import 'src/styles/variables';

.header {
  height: $heightHeader;
  position: relative;
  z-index: 998;
  margin-bottom: 0;
}

.position {
  @media screen and (max-width: 1024px) {
    position: absolute;
  }
}

.wrap {
  background-color: $mainBackgroundColor;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 11;
  transition: 0.3s ease;
  padding: 1.6rem;
  transform: translateY(-$heightHeader);

  @media screen and (min-width: 1024px) {
    padding: 1.6rem $desktopPaddingContainer;
    transform: translateY(-($heightHeader + 1rem));
  }

  &._main_page {
    top: getRem(-9);
    transform: translateY(-($heightHeader + 10rem));
    @media screen and (min-width: 1024px) {
      top: getRem(-10);
    }
  }

  &.transparent {
    background: transparent;
  }

  &._fixed {
    transform: translateY(0);
    box-shadow: 0 0.2rem 2rem rgba(0, 0, 0, 0.15);
  }

  &._fixed_on_top {
    border-bottom-color: transparent;
    box-shadow: none;
  }
}

.alignLeft {
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
}

.navigation {
  height: 100%;
  font-style: normal;
  font-weight: normal;
  font-size: 1.6rem;
  line-height: 2.2rem;
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  max-width: none;
  animation: logoShrink 0.2s ease forwards;
  width: 12.6rem;
  height: 3.2rem;
  margin-right: 0;
  @media screen and (min-width: 1024px) {
    width: 22.6rem;
    height: 4rem;
    margin-right: 9rem;
  }
  @media not all and (min-resolution: 0.001dpcm) {
    animation: logoShrink 0s ease forwards;
  }
  &._logo_fixed {
    position: absolute;
    margin-right: 0;
    animation: logoGrow 0.2s ease forwards;
    pointer-events: none;
    @media screen and (min-width: 1024px) {
      position: static;
      margin-right: -22.6rem;
    }
    @media not all and (min-resolution: 0.001dpcm) {
      animation: logoGrow 0s ease forwards;
    }
  }
}

@keyframes logoGrow {
  from {
  }
  to {
    margin-right: -12.6rem;
    // starting position on mobile devices
    left: 50%;
    transform: translate(-50%, 6rem) scale(2.8);
  }
}

@media screen and (min-width: 1024px) {
  @keyframes logoGrow {
    0% {
      margin-right: 9rem;
    }
    100% {
      margin-right: -22.6rem;
      transform: translate(40vw, 14rem) scale(6.7, 6.7);
    }
  }
}

@keyframes logoShrink {
  from {
    transform: translate(30vw, 6rem) scale(2.8);
  }
  to {
  }
}

@media screen and (min-width: 1024px) {
  @keyframes logoShrink {
    0% {
      margin-right: -22.6rem;
      transform: translate(42vw, 12.5rem) scale(6.7, 6.7);
    }
    100% {
      margin-right: 9rem;
    }
  }
}

.opacity {
  opacity: 0;
}
