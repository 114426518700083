.container {
  position: relative;
}

.WAWidget {
  position: fixed;
  bottom: 3rem;
  right: 3rem;
  z-index: 9000;
  margin: 0;
  padding: 0;
  transition: all 0.3s ease-in-out;
  border-radius: 50%;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.16));
  &:hover,
  &.active {
    transform: scale(1.1);
    &::after {
      opacity: 1;
    }
  }
  &.bigger {
    transform: scale(1.1);
  }
  &::after {
    position: absolute;
    right: 3.5rem;
    bottom: 7.5rem;
    content: 'Converse com um consultor :)';
    background: #f4f5f6;
    border: 1px solid #d3d4df;
    font-size: 2rem;
    width: max-content;
    color: black;
    padding: 2rem 2rem 1.7rem;
    border-radius: 2rem 2rem 0 2rem;
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
  &.lift {
    bottom: 16rem;
  }
}

.WAWidget_icon {
  box-sizing: content-box;
  height: 7rem;
  width: 7rem;
  object-fit: contain;
  cursor: pointer;
}
