@import './src/styles/variables';

.nav-tabs {
  display: none;
  color: #fff;
  text-decoration: none;
  padding: 0.9rem 1.6rem 0.7rem;
  border: 1px solid #fff;
  border-radius: 1.6rem;
  z-index: 15;

  &:not(:last-child) {
    margin-right: 0.8rem;
  }

  @media screen and (min-width: $desktop-width) {
    display: inline-block;
  }
}
