@import 'src/styles/variables';
@import 'src/styles/mixins';

.container {
  position: relative;
  overflow: hidden;
  width: 100%;
  background-color: $mainBackgroundColor;
  z-index: 1;
}

.online-school {
  width: 100rem;
  height: 60rem;
  position: absolute;
  max-width: none;
  top: 16rem;
  left: 4rem;
  @media screen and (max-width: 1024px) {
    width: 39rem;
    height: 18rem;
    top: 1rem;
    right: 0.1rem;
    left: auto;
  }
}

.wrapper {
  margin: 12rem 9rem 12rem auto;
  max-width: 44.6rem;
  a {
    @include font-codec(Bold, 40, 40);

    display: block;
    text-decoration: none;
    color: #ffffff;

    font-style: normal;

    margin-top: 2.4rem;
  }
  @media screen and (max-width: 1024px) {
    padding: 2rem $mobilePaddingContainer 4.2rem;
    margin: 21rem 0 0;
    a {
      font-size: 2.4rem;
      line-height: 2.4rem;

      margin-top: 1.2rem;
    }
  }
}

.text {
  @include font-codec(Bold, 18, 24);
  margin-top: 2.4rem;

  color: #ffffff;

  font-style: normal;
  @media screen and (max-width: 1024px) {
    margin-top: 1rem;
    @include font-codec(Regular, 16, 24);
    font-weight: normal;
    font-size: 1.6rem;
    line-height: 2.4rem;
  }
}

.wrapper-icons {
  display: flex;
  margin-left: 0.6rem;
  margin-bottom: getRem(20);
  @media screen and (min-width: 1024px) {
    margin-bottom: getRem(32);
  }

  // a {
  //   margin-top: 0px;
  // }
}

.wrapper-download-links {
  display: flex;
  column-gap: getRem(32);
  margin-bottom: getRem(10);
  @media screen and (min-width: 1024px) {
    margin-bottom: getRem(64);
  }
  a {
    margin: 0;
    font-size: 0;
    line-height: 0;
  }
}

.download-link-icon {
  width: auto;
  height: getRem(40);
}

.logo-abed {
  height: auto;
  width: getRem(112);
  object-fit: contain;
  @media screen and (max-width: 1024px) {
    width: getRem(80);
    margin-bottom: getRem(20);
  }
}

.social-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4.8rem;
  height: 7.2rem;
  margin-right: 1.5rem;
  background: linear-gradient(146.31deg, #ff8d5c 14.73%, #fa2e6b 91.92%);
  border-radius: 50%;
  transform: rotate(45deg);
}

.icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
}
