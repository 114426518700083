@font-face {
  font-family: 'CodecPro';
  src: url('~/public/fonts/CodecPro-Regular.woff2') format('woff2'),
    url('~/public/fonts/CodecPro-Regular.woff') format('woff');
  font-style: normal;
  font-weight: normal;
  font-display: swap;
}
@font-face {
  font-family: 'CodecPro-Bold';
  src: url('~/public/fonts/CodecPro-Bold.woff2') format('woff2'),
    url('~/public/fonts/CodecPro-Bold.woff') format('woff');
  font-style: normal;
  font-weight: bold;
  font-display: swap;
}
