@import './src/styles/variables';

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  padding-top: $heightHeader;
  background-color: $mainBackgroundColor;
  overflow: hidden;
}

.gradient {
  z-index: 2;
}

.wrapper {
  overflow: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
}
