.burger {
  -webkit-overflow-scrolling: none !important;
}

.burger {
  width: 3.2rem;
  height: 3.2rem;
  cursor: pointer;
}

.cross {
  width: 2.4rem;
  height: 2.4rem;
  cursor: pointer;
}
