@import './src/styles/variables';

.container {
  position: relative;
  padding: getRem(40) $desktopPaddingContainer getRem(33);

  border-radius: getRem(20) getRem(20) 0 0;
  background-color: #000000;

  z-index: 10;
  @media screen and (max-width: 1024px) {
    padding: getRem(32) $mobilePaddingContainer getRem(24);
    margin-top: 0;
  }
  &.minimized {
    border-radius: getRem(40) getRem(40) 0 0;
    @media screen and (max-width: 1024px) {
      border-radius: getRem(20) getRem(20) 0 0;
      .wrapperDocuments {
        margin-top: 0;
      }
    }
  }
}

.buttonWrapper {
  height: 6.4rem;
  width: 100%;
  text-transform: uppercase;
  @media screen and (max-width: 1024px) {
    height: 5.6rem;
  }
}

.legalSection {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: getRem(24);
  @media screen and (max-width: 1024px) {
    flex-direction: column;
    row-gap: 2rem;
  }
  .text {
    text-decoration: none;
    color: #ffffff;
    line-height: 1.5;
    white-space: pre-wrap;
  }
}
.wrapperDocuments {
  display: flex;
  flex-wrap: wrap;

  @media screen and (max-width: 1024px) {
    margin-top: 2rem;
  }
}

.text {
  color: #ffffff;
  font-family: 'CodecPro-Bold', sans-serif;
  font-style: normal;
  font-size: 1.8rem;
  line-height: 2.4rem;
  @media screen and (max-width: 1024px) {
    font-size: 1.6rem;
    line-height: 1.6rem;
  }
}

.document {
  margin-right: 6.4rem;
  display: block;
  @media screen and (max-width: 1024px) {
    margin-top: 0.8rem;
    margin-right: 2.4rem;
  }
}

.address {
  margin-left: auto;
  @media screen and (max-width: 1024px) {
    margin-left: 0;
    margin-top: 2.4rem;
  }
}

.mobile {
  display: inline-block;

  @media screen and (min-width: $desktop-width) {
    display: none;
  }
}

.desktop {
  display: none;

  @media screen and (min-width: $desktop-width) {
    display: inline-block;
  }
}
