:root {
  --txt-main:  #000000;
  --txt-white: #FFFFFF;
  --txt-add:   #868E96;
  --txt-gold:   #EDC855;

  --bg-main:             #FFFFFF;
  --bg-secondary:        #F4F5F6;
  --bg-brand:              linear-gradient(180deg, #5CE2FF 0%, #0011AA 100%);
  --bg-brand-bg-secondary: linear-gradient(180deg, #7190FF -78.14%, #DBCBE7 25.7%, #F5DAE1 105.42%);

  --stroke-main:      #000;
  --stroke-secondary: #D3D4DF;

  --dsn-main:  #1865FF;
  --dsn-add:   #D7B8FF;
  --pr-main:   #FEDE33;
  --pr-add:    #FF9462;
  --mrkt-main: #C23EEF;
  --mrkt-add:  #FB4571;
  --gd-main:   #59EAA4;
  --gd-add:    #AED9FF;

  --bg-dsn-main:    linear-gradient(180deg, #D8B8FF 0%, #C0AFFF 34.3%, #3378FF 100%);
  --bg-dsn-main-r:  linear-gradient(0deg, #D8B8FF 0%, #C0AFFF 34.3%, #3378FF 100%);
  --bg-dsn-add:     linear-gradient(180deg, #3378FF 0%, #3378FF 76.21%, rgba(51, 120, 255, 0) 100%);
  --bg-pr-main:     linear-gradient(180deg, #FDDD33 0%, #FDD439 23.5%, #FF9E5B 68%, #FF9263 100%);
  --bg-pr-add:      linear-gradient(180deg, #FF9263 0%, #FF9263 81.55%, rgba(255, 146, 99, 0) 100%);
  --bg-mrkt-main:   linear-gradient(180deg, #FA4470 7.08%, #E6389E 33%, #C524E8 68%, #BF21F5 100%);
  --bg-mrkt-main-r: linear-gradient(0deg, #FA4470 0%, #E6389E 27.9%, #C524E8 65.56%, #BF21F5 100%);
  --bg-mrkt-add:    linear-gradient(180deg, #C13DEE 0%, #C13DEE 80.55%, rgba(193, 61, 238, 0) 100%);
  --bg-gd-main:     linear-gradient(180deg, #C6E3FF 0%, #A0E5E0 29.2%, #58E9A3 63.78%, #58E971 100%, #58E9A3 100%);
  --bg-gd-add:      linear-gradient(180deg, #58E9A3 0%, #58E9A3 68.7%, rgba(88, 233, 163, 0) 100%);

  --gd-blue: linear-gradient(270deg, #1865FF 0%, #35B4EA 100%);

  --btn-blue:         #1865FF;
  --btn-blue-hover:   #004EE9;
  --btn-blue-active:  #0042C6;

  --btn-yellow:       #FEDE33;
  --btn-yellow-hover: #FFD700;
  --btn-yellow-active:#F1CC00;

  --btn-orange:       #FF9462;
  --btn-orange-hover: #FF7A3C;
  --btn-orange-active:#FF6720;
}
