@import './colors';
@import './typography';
@import './src/styles/variables';
@import './src/styles/mixins';
@import './src/styles/adaptive';

html {
  font-size: calc-between-width(2, 0.05, 375, 10);

  @media screen and (max-width: $mobile-width-only) {
    font-size: calc-between-width(375, 10, 1024, 27.3);
  }

  @media screen and (min-width: $desktop-width) {
    font-size: calc-between-width(1025, 6.4, 1600, 10);
  }
}

body {
  overflow-y: scroll;
}

body {
  overflow-y: scroll;
}

.common-block-container {
  @include standard-block-margin();
}

.common-slider-container {
  @media screen and (min-width: 1025px) {
    margin: 0 (-$desktopPaddingContainer) getRem(104);
    padding: 0 $desktopPaddingContainer;
  }
  @media screen and (max-width: 1024px) {
    margin: 0 (-$mobilePaddingContainer) getRem(64);
    padding: 0 $mobilePaddingContainer;
  }
  &:last-child {
    margin-bottom: 0;
  }
}

.default-layout {
}

.default-inner {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: getRem(64);
  background-color: #ffffff;
  margin: -2rem 0;
  padding: $mobilePaddingWrapper;
  border-radius: getRem(20);
  z-index: 100;

  &:first-child {
    margin-top: -2rem;
    border-top: none;
  }

  @media screen and (min-width: $desktop-width) {
    gap: getRem(104);
    margin: -4rem 0;
    padding: $desktopPaddingWrapper;
    border-radius: getRem(40);
  }
}

.marked-list-checkmark {
  ul,
  ol {
    position: relative;
    display: flex;
    flex-direction: column;
    row-gap: getRem(8);
    margin: getRem(16) 0;
    @media screen and (min-width: $desktop-width) {
      margin: getRem(24) 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  ul {
    list-style-type: none;
  }
  ul {
    li {
      margin-left: 2.8rem;
      &::marker {
        display: none;
        content: none;
      }
      &::-webkit-details-marker {
        display: none;
        content: none;
      }
      &::before {
        position: absolute;
        content: url('~/public/images/checkmark-blue.svg');
        width: getRem(12);
        height: getRem(20);
        margin-top: getRem(2);
        left: getRem(4);
        @media screen and (min-width: $desktop-width) {
          margin-top: 0;
        }
      }
    }
  }
  ol {
    li {
      margin-left: 1em;
    }
  }
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

// -- privy spinner styling -- //
#privy-container #privy-inner-container .privy-spin-to-win-container {
  z-index: 2147483647 !important;
}

.privy-tab-container {
  display: none;
}

html body {
  font-feature-settings: 'kern';
  font-kerning: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  min-height: 100%;
  font-family: 'CodecPro', sans-serif;
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
  text-decoration: none;
  margin: 0;
  padding: 0;
}

*,
*:hover,
*:focus {
  outline: none;
}

img {
  max-width: 100%;
}

button,
input {
  font-family: 'CodecPro', sans-serif;
}
